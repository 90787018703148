export default {
  extendedBordersOptions: false,
  borders: [
    {
      title: 't_border-top-title_',
      side: 'border-top'
    },
    {
      title: 't_border-right-title_',
      side: 'border-right'
    },
    {
      title: 't_border-bottom-title_',
      side: 'border-bottom'
    },
    {
      title: 't_border-left-title_',
      side: 'border-left'
    }
  ],
  items: [
    {
      label: 't_solid_label_',
      value: 'solid'
    },
    {
      label: 't_dotted_label_',
      value: 'dotted'
    },
    {
      label: 't_dashed_label_',
      value: 'dashed'
    }
  ]
}
